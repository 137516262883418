<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Wawancara > Data Jadwal Wawancara > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Hasil Wawancara - {{ data.nama_anak }} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="form-general">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="col-12 spacer-field-sga">
                <normal-label>No formulir</normal-label>
                <br>
                <normal-label2>{{ data.no_formulir }}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Ananda</normal-label>
                <br>
                <normal-label2>{{ data.nama_anak }}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Orang Tua</normal-label>
                <ul>
                  <li>
                    <normal-label2>{{ data.nama_ayah }}</normal-label2>
                  </li>
                  <li>
                    <normal-label2>{{ data.nama_ibu }}</normal-label2>
                  </li>
                  <li v-if="data.nama_ayah_tiri">{{ data.nama_ayah_tiri }}</li>
                  <li v-if="data.nama_ibu_tiri">{{ data.nama_ibu_tiri }}</li>
                </ul>
              </div>
              <div class="col-12 spacer-field-sga"><label>Yang hadir</label><br>
                <ul class="list-group mb-2">
                  <li v-for="(ortu, i) in datawawancara.listhadir" :key="i" class="list-group-item"><i class="fa fa-remove" style="cursor: pointer;" @click="removehadir(ortu)"></i> {{ortu}}</li>
                </ul>
                <select v-model="hadir" id="" class="mr-2">
                  <option v-if="data.nama_ayah" :value=data.nama_ayah>{{data.nama_ayah}}</option>
                  <option v-if="data.nama_ibu" :value=data.nama_ibu>{{data.nama_ibu}}</option>
                  <option v-if="data.nama_ayah_tiri" :value=data.nama_ayah_tiri>{{data.nama_ayah_tiri}}</option>
                  <option v-if="data.nama_ibu_tiri" :value=data.nama_ibu_tiri>{{data.nama_ibu_tiri}}</option>
                </select>
                <button class="btn btn-sm btn-success mt-2" @click="yanghadir">Hadir</button>
              </div>
              <div class="col-12 spacer-field-sga">
                <label>Pewawancara</label><br>
                <input type="text" v-model="datawawancara.pewawancara" class="w-100 form-control" required="">
                <keterangan-form>Tuliskan nama</keterangan-form>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a :class="getClass(2)" @click="setpage(2)" data-toggle="tab" href="#menu1">Data yang tersedia</a>
              </li>
              <li class="nav-item">
                <a :class="getClass(1)" @click="setpage(1)" data-toggle="tab" href="#home">Catatan Wawancara</a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content" style="margin-top: 30px;">
              <div :class="getTabClass(1)" id="home">
                <div class="row">
                  <div class="col-12">
                    <div class="notes-notification-sga"><strong>Jenis Wawancara</strong>
                      <select v-model="datawawancara.jenis">
                        <option value="Normal">Wawancara Normal</option>
                        <option value="Tolak">Wawancara Tolak</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h5 style="color: darkorange;">Hubungan dan komunikasi</h5>
                  </div>
                  <div class="col-12 spacer-field-sga  w-100">
                    <normal-label>Keterangan umum</normal-label>
                  </div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.komunikasi.keteranganumum" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Pola komunikasi</label></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.komunikasi.polakomunikasi" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Respek</label></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.komunikasi.respek" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Terima masukan</label></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.komunikasi.terimamasukan" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Energi saat berdiskusi</label></div>
                  <div class="col-12  spacer-field-sg">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" v-model="datawawancara.komunikasi.energidiskusi" value="mengalir"
                             name="energiwawancara" required="">
                      <normal-label2 class="form-check-label" for="inlineCheckbox1">mengalir</normal-label2>
                    </div>
                    <br>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" v-model="datawawancara.komunikasi.energidiskusi" value="berat"
                             name="energiwawancara" required="">
                      <normal-label2 class="form-check-label" for="inlineCheckbox2">berat</normal-label2>
                    </div>
                  </div>
                  <!--pemisah-->
                </div>
                <hr>
                <div class="row">
                  <div class="col-12" style="mar"><h5 style="color: darkorange;">Pola pengasuhan dan keterlibatan</h5>
                  </div>
                  <div class="col-12 spacer-field-sga  w-100">
                    <normal-label>Keterangan umum</normal-label>
                  </div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.pola.keteranganumum" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Pola asuh</label></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.pola.polasuh" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Struktur dalam keluarga</label></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.pola.struktur" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Praktik dan keterlibatan dalam pendidikan di
                    rumah</label></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.pola.praktik" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                  <div class="col-12 spacer-field-sga  w-100"><label>Kontribusi untuk Gemala Ananda</label></div>
                  <div class="col-12  spacer-field-sga">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.pola.kontribusi" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                </div>
                <hr>
                <div class="row">
                  <div class="col-12" style="mar"><h5 style="color: darkorange;">Lain lain</h5></div>
                  <div class="col-12  spacer-field-sg">
                    <textarea class="form-control textarea-sga" v-model="datawawancara.pola.lainlain" rows="2" required=""></textarea>
                  </div>
                  <!--pemisah-->
                </div>
                <hr>
                <div class="row">
                  <div class="col-12">
                    <div class="notes-notification-sga"><p><strong>Panduan scoring penilaian</strong></p>
                      <ul>
                        <li>Centang bila nilai terdeteksi</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <hr>
                  <div class="col-12" style="mar"><h5 style="color: darkorange;">Penilaian kompetensi perilaku ayah</h5>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-striped ">
                        <thead>
                        <tr>
                          <th scope="col" style="vertical-align: middle">Kompetensi<br>Perilaku</th>
                          <th scope="col" style="vertical-align: middle">Level 1</th>
                          <th scope="col" style="vertical-align: middle">Level 2</th>
                          <th scope="col" style="vertical-align: middle">Level 3</th>
                          <th scope="col" style="vertical-align: middle">Level 4</th>
                          <th scope="col" style="vertical-align: middle">Level 5</th>
                          <th scope="col" style="vertical-align: middle">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th scope="row">Integritas (INT) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.integritas.level1" value=1><br>Taat aturan
                          </td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.integritas.level2" value=1><br>Tepat janji
                          </td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.integritas.level3" value=1><br>Jujur &amp; transparan
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.integritas.level4" value=1><br>Menjaga etika
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.integritas.level5" value=1><br>Asertif dlm kebenaran, kebaikan
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.integritas.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Belajar &amp; berkembang<br> (BLJ) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.berkembang.level1" value="1"><br>Senang belajar &amp; membaca</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.berkembang.level2" value="1"><br>Mengimple-mentasi hasil belajar</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.berkembang.level3" value="1"><br>Berbagi</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.berkembang.level4" value="1"><br>Berinovasi</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.berkembang.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Kolaborasi<br>(KLB) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.kolaborasi.level1" value="1"><br>Paham, jalankan tugas &amp; peran dalam keluarga</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.kolaborasi.level2"  value="1"><br>Paham, jalankan tugas &amp; peran pendidikan anak
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.kolaborasi.level3" value="1"><br>Bersinergi dlm komunitas dgn basis kekuatan</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.kolaborasi.level4" value="1"><br>Bangga pada pencapaian tim</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.kolaborasi.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Pengendalian diri<br>(PnD) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.pengendaliandiri.level1" value="1"><br>Menghindari &amp; menjauhi godaan</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.pengendaliandiri.level2" value="1"><br>Membawa diri dengan tenang</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.pengendaliandiri.level3"  value="1"><br>Bertindak konstruktif meski dalam tekanan</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.pengendaliandiri.level4" value="1"><br>Menenang-kan orang lain</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.pengendaliandiri.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Membangun hubungan<br>
                            (MBH) – OTS
                          </th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.membangunhubungan.level1" value="1"><br>Melakukan kontak informal</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ayah.membangunhubungan.level2" value="1"><br>Membangun hubungan personal</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.membangunhubungan.level3" value="1"><br>Membangun hubungan sinergis dalam komunitas</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.membangunhubungan.level4" value="1"><br>Berperan aktif membangun komunitas</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ayah.membangunhubungan.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <hr>
                  <div class="col-12" style="mar"><h5 style="color: darkorange;">Penilaian kompetensi perilaku Ibu</h5>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-striped ">
                        <thead>
                        <tr>
                          <th scope="col" style="vertical-align: middle">Kompetensi<br>Perilaku</th>
                          <th scope="col" style="vertical-align: middle">Level 1</th>
                          <th scope="col" style="vertical-align: middle">Level 2</th>
                          <th scope="col" style="vertical-align: middle">Level 3</th>
                          <th scope="col" style="vertical-align: middle">Level 4</th>
                          <th scope="col" style="vertical-align: middle">Level 5</th>
                          <th scope="col" style="vertical-align: middle">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th scope="row">Integritas (INT) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.integritas.level1" value="1"><br>Taat aturan
                          </td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.integritas.level2" value="1"><br>Tepat janji
                          </td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.integritas.level3" value="1"><br>Jujur &amp; transparan
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.integritas.level4" value="1"><br>Menjaga etika
                          </td>
                          <td><input type="checkbox"  v-model="datawawancara.nilai.ibu.integritas.level5" value="1"><br>Asertif dlm kebenaran, kebaikan
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.integritas.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Belajar &amp; berkembang<br> (BLJ) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.berkembang.level1" value="1"><br>Senang belajar &amp; membaca</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.berkembang.level2" value="1"><br>Mengimple-mentasi hasil belajar</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.berkembang.level3" value="1"><br>Berbagi</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.berkembang.level4" value="1"><br>Berinovasi</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.berkembang.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Kolaborasi<br>(KLB) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.kolaborasi.level1" value="1"><br>Paham, jalankan tugas &amp; peran dalam keluarga</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.kolaborasi.level2" value="1"><br>Paham, jalankan tugas &amp; peran pendidikan anak
                          </td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.kolaborasi.level3" value="1"><br>Bersinergi dlm komunitas dgn basis kekuatan</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.kolaborasi.level4" value="1"><br>Bangga pada pencapaian tim</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.kolaborasi.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Pengendalian diri<br>(PnD) – OTS</th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.pengendaliandiri.level1" value="1"><br>Menghindari &amp; menjauhi godaan</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.pengendaliandiri.level2" value="1"><br>Membawa diri dengan tenang</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.pengendaliandiri.level3" value="1"><br>Bertindak konstruktif meski dalam tekanan</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.pengendaliandiri.level4" value="1"><br>Menenang-kan orang lain</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.pengendaliandiri.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        <tr>
                          <th scope="row">Membangun hubungan<br>
                            (MBH) – OTS
                          </th>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.membangunhubungan.level1" value="1"><br>Melakukan kontak informal</td>
                          <td class="bg-green"><input type="checkbox" v-model="datawawancara.nilai.ibu.membangunhubungan.level2" value="1"><br>Membangun hubungan personal</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.membangunhubungan.level3" value="1"><br>Membangun hubungan sinergis dalam komunitas</td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.membangunhubungan.level4" value="1"><br>Berperan aktif membangun komunitas</td>
                          <td></td>
                          <td><input type="checkbox" v-model="datawawancara.nilai.ibu.membangunhubungan.level6" value="0"><br>Tidak teramati</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12 spacer-field-sga d-none">
                    <normal-label>Status data: <select v-model="datawawancara.statusdata"  class="sga-status-dropdown">
                      <option value="belum-lengkap">Belum lengkap</option>
                      <option value="lengkap">Lengkap</option>
                    </select></normal-label>
                  </div>
                  <div class="col-12 button-area-sga text-right">
                    <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                    <AlertDanger v-else :message="messagetrx"></AlertDanger>
                    <button @click="updateHasilWawancaraOrtu" class="btn btn-sm btn-success d-none" type="submit"> Simpan data dan kalkulasi kesimpulan</button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12">
                    <div class="notes-notification-sga2"><p><strong>KESIMPULAN</strong></p>
                      <p>berdasarkan seluruh data administratif siswa dan orang tua, pengamatan siswa oleh guru kelas,
                        asesmen siswa oleh pengamat, wawancara orang tua, dan interaksi orang tua selama proses PSB.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <h5>Ringkasan anak</h5>
                    <div class="row">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table class="table table-striped ">
                            <thead>
                            <tr>
                              <th scope="row">Nilai Akademik</th>
                              <th scope="col">Nilai Sikap</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>{{datawawancara.score.anak.akademik}}</td>
                              <td>{{datawawancara.score.anak.sikap}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-12  spacer-field-sga">
                      <label>Catatan Anak </label>
                      <textarea class="form-control textarea-sga" v-model="datawawancara.catatananak" rows="4" required=""></textarea>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <h5>Ringkasan orang tua</h5>
                    <div class="row">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table class="table table-striped ">
                            <thead>
                            <tr>
                              <th scope="row">Ayah</th>
                              <th scope="row">Ibu</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              {{hitungscoringortu(datawawancara.nilai.ayah,'ayah')}}
                              {{hitungscoringortu(datawawancara.nilai.ibu,'ibu')}}
                              <td>{{datawawancara.score.ayah}}</td>
                              <td>{{datawawancara.score.ibu}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-12  spacer-field-sga">
                      <label>Catatan orang tua </label>
                      <textarea class="form-control textarea-sga" v-model="datawawancara.catatanortu" rows="4" required=""></textarea>
                    </div>
                  </div>
<!--                  <div class="col-12 text-right"><button @click="docalculate" class="btn btn-sm btn-success" style="margin-top: 25px;">Rekomendasi sistem</button></div>-->
                </div>
                <div class="row">
                  <div class="col-12 spacer-field-sga  w-100">
                    <normal-label>Status Rekomendasi : <strong>{{datawawancara.statusrekomendasi}}</strong></normal-label>
                  </div>
                  <div class="col-12 spacer-field-sga">
                    <normal-label>Status rekomendasi oleh pewawancara : <select
                        v-model="datawawancara.statusrekomendasi2" class="sga-status-dropdown">
                      <option value="Belum ada rekomendasi">Belum ada rekomendasi</option>
                      <option value="Ditolak">Ditolak</option>
                      <option value="Diterima dengan syarat">Diterima dengan syarat</option>
                      <option value="Diterima dengan catatan">Diterima dengan catatan</option>
                      <option value="Diterima">Diterima</option>
                      <option value="Perlu diskusi lanjut">Perlu diskusi lanjut</option>
                    </select></normal-label>
                  </div>
                  <div style="padding:25px; border: solid 1px #333; margin-top: 25px;" class="w-100" v-if="catatanrequired">
                    <normal-label>Catatan atau syarat penerimaan</normal-label>
                      <textarea v-model="datawawancara.catatan" class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="4"
                              required=""></textarea>
                  </div>
                  <div style="padding:25px; border: solid 1px #333; margin-top: 25px;" class="w-100" v-if="diskusirequired">
                    <h5>CATATAN DISKUSI LANJUTAN & PENGAMBILAN KEPUTUSAN</h5>
                    <div class="row">
                      <div class="col-md-4 spacer-field-sga"><label>Peserta diskusi</label></div>
                      <div class="col-md-8 spacer-field-sga"><input type="text" v-model="datawawancara.pesertadiskusi" class="w-100 form-control" required>
                        <keterangan-form>Tuliskan nama</keterangan-form>
                      </div>
                      <div class="col-md-4 spacer-field-sga"><label>Tanggal Diskusi</label></div>
                      <div class="col-md-8 spacer-field-sga">
                        <div class="datepicker date input-group">
                          <DatePicker v-model="datawawancara.tanggaldiskusi"  class="date-picker-sga form-control" dateFormat="dd/mm/yy" required/>
                        </div>
                        <keterangan-form>tanggal dilaksanakan diskusi</keterangan-form>
                      </div>
                      <div class="col-md-4 spacer-field-sga"><label>Laporan dibuat oleh</label>
                      </div>
                      <div class="col-md-8 spacer-field-sga">
                        <input type="text" v-model="datawawancara.namadiskusi" class="w-100 form-control" required>
                        <keterangan-form>Tuliskan nama</keterangan-form>
                      </div>
                      <div class="col-12  spacer-field-sga">
                        <label>Kondisi/tindak lanjut yang didiskusikan</label>
                        <textarea v-model="datawawancara.tindaklanjutdiskusi" class="form-control textarea-sga" rows="4" required=""></textarea>
                      </div>
                      <div class="col-12  spacer-field-sga">
                        <label>Pertimbangan-pertimbangan dalam diskusi</label>
                        <textarea v-model="datawawancara.pertimbangandiskusi" class="form-control textarea-sga" rows="4" required=""></textarea>
                      </div>
                      <div class="col-12  spacer-field-sga">
                        <label>Keputusan akhir:
                          <select v-model="datawawancara.keputusandiskusi" class="sga-status-dropdown">
                            <option value="Belum ada rekomendasi">Belum ada rekomendasi</option>
                            <option value="Ditolak">Ditolak</option>
                            <option value="Diterima dengan syarat">Diterima dengan syarat</option>
                            <option value="Diterima dengan catatan">Diterima dengan catatan</option>
                            <option value="Diterima">Diterima</option>
                          </select>
                        </label>
                      </div>
                      <div class="col-md-4 spacer-field-sga"><label>Tanggal laporan dibuat</label>
                      </div>
                      <div class="col-md-8 spacer-field-sga">
                        <div class="datepicker date input-group">
                          <DatePicker v-model="datawawancara.tanggallaporan" class="date-picker-sga form-control" dateFormat="dd/mm/yy" required/>
                        </div>
                        <keterangan-form>tanggal pelaporan</keterangan-form>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                    <AlertDanger v-else :message="messagetrx"></AlertDanger>
                    <button @click="updateHasilWawancaraOrtu" class="btn btn-sm btn-success " style="margin-top: 25px;">Simpan data </button></div>
                </div>
                <div class="row">
                  <div class="col-12 spacer-field-sga">
                    <normal-label>Hari, tanggal dan jam laporan terakhir diubah</normal-label>
                    <br>
                    <normal-label2>{{datadiubah}}</normal-label2>
                  </div>
                </div>
              </div>
              <div :class="getTabClass(2)" id="menu1">
                <div class="row">
                  <div class="col-12"><h5 style="color: darkorange;">Formulir pendaftaran</h5>
                    <p>
                      <router-link :to=generatelink(data.invid) target='_blank'>
                        Lihat formulir
                      </router-link>
                    </p>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data wawancara awal
                    (Psikologi)</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{ datacatatan.psikolog }}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPsikolog.list"
                                    :num-visible="filelistPsikolog.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data test kognitif </h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{ datacatatan.kognitif }}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistKognitif.list"
                                    :num-visible="filelistKognitif.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data kelas coba belajar</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan PIC PSB</strong> :</p>
                        <p>{{ datacatatan.cobabelajar }}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPercobaan.list"
                                    :num-visible="filelistPercobaan.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data formulir Berasa</h5>
                    <div class="row">
                      <div class="col-12">
                        <ul>
                          <li v-for="(berasa,idx) in berasa" :key="idx">
                            <span class="text-primary" style="cursor: pointer" @click="viewberasa(berasa)"
                                  target="_blank">{{ berasa.tipe_pengisi }}</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data Catatan Diskusi</h5>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Akademik (maksimal 21)</strong>:</p>
                        <p>{{ datacatatan.catatandiskusi.scoring_akademik }}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Akademik Anak</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.catatan_akademik }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Sikap (maksimal 9)</strong>:</p>
                        <p>{{ datacatatan.catatandiskusi.scoring_sikap }}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Sikap Anak</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.catatan_sikap }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12"><p><strong>Yang masih perlu dikembangkan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.masih_perlu_kembangkan }}</p>
                      </div>
                      <div class="col-12"><p><strong>Apa yang perlu ditanyakan lebih lanjut kepada
                        orang tua</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.tanya_ortu }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kualitas informasi</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kualitas_informasi }}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kualitas_informasi_catatan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Temperamen</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.temperamen }}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.temperamen_catatan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Keterlibatan orang tua</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.keterlibatan_ortu }}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.keterlibatan_ortu_catatan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kedekatan dalam keluarga</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kedekatan_keluarga }}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kedekatan_keluarga_catatan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Aktivitas</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.aktivitas }}</p>
                      </div>
                      <div class="col-4"><p><strong>Kemandirian</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kemandirian }}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kemandirian_catatan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Limitasi kesehatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.limitasi }}</p>
                      </div>
                      <div class="col-4"><p><strong>Kebutuhan khusus/kesubel</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kebutuhan_khsusu }}</p>
                      </div>
                      <div class="col-4"><p><strong>Tambahan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.tambahan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Raport TK</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.raport_tk }}</p>
                      </div>
                      <div class="col-4"><p><strong>Kehadiran</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.kehadiran }}</p>
                        <p>{{ datacatatan.catatandiskusi.kehadiran_catatan }}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{ datacatatan.catatandiskusi.catatan_akhir }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="visible" modal :header="berasaheader" :style="{ width: '50rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <ViewHasilBerasa :databerasa="currentberasa"></ViewHasilBerasa>
  </Dialog>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import FileLister from "@/components/FileLister";
import DashboardServices from "@/services/dashboard.services";
import Dialog from 'primevue/dialog';
import ViewHasilBerasa from "@/components/viewadmin/wawancara/ViewHasilBerasa";
import useValidate from '@vuelidate/core'
import {required, requiredIf,minValue} from "@vuelidate/validators/dist";
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
import DatePicker from 'primevue/datepicker';
export default {
  name: "PageUpdateDataWawancaraNew",
  props: {
    data: undefined
  },
  emits: ['change-view'],
  components: {AlertSuccess, AlertDanger, ViewHasilBerasa, BreadCrumb, FileLister, Dialog,DatePicker},
  data: () => ({
    v$: useValidate(),
    loading: false,
    message:"",
    messagetrx:"",
    success: false,
    successtrx: false,
    showupload: true,
    classupload1: '',
    urlupload: '',
    uploadLabel1: '',
    visible: false,
    hadir:'',
    filelist: [],
    filelistPercobaan: [],
    filelistKognitif: [],
    filelistPsikolog: [],
    page: 2,
    statuspelaksanaan: '',
    currentberasa: [],
    catatan: '',
    berasa: [],
    berasaheader: "",
    datadiubah:"",
    tanpacatatan:false,
    dengancatatan: false,
    daftarhadir:[],
    datawawancara:{
      jenis:"",
      statusdata:"lengkap",
      statusrekomendasi:"",
      statusrekomendasi2:"",
      catatan:"",
      catatanortu:'',
      catatananak:'',
      pesertadiskusi:"",
      tanggaldiskusi:"",
      namadiskusi:"",
      tindaklanjutdiskusi:"",
      pertimbangandiskusi:"",
      keputusandiskusi:"",
      tanggallaporan:"",
      pewawancara:"",
      listhadir:[],
      komunikasi : {
        keteranganumum:"",
        polakomunikasi:"",
        respek:"",
        terimamasukan:"",
        energidiskusi:"",
      },
      pola:{
        keteranganumum:"",
        polasuh:"",
        struktur:"",
        praktik:"",
        kontribusi:"",
        lainlain:"",
      },
      nilai:{
        ayah:{
          integritas:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          berkembang:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          kolaborasi:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          pengendaliandiri:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          membangunhubungan:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
        },
        ibu:{
          integritas:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          berkembang:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          kolaborasi:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          pengendaliandiri:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
          membangunhubungan:{
            level1:"",
            level2:"",
            level3:"",
            level4:"",
            level5:"",
            level6:"",
          },
        }
      },
      score:{
        anak:{
          akademik:0,
          sikap:0
        },
        ayah:0,
        ibu:0
      }
    },
    datacatatan: {
      psikolog: '',
      cobabelajar: '',
      kognitif: '',
      catatandiskusi: []
    },
    datakegiatan: []
  }),
  computed:{
    diskusirequired(){
      if(this.datawawancara.statusrekomendasi2==="Perlu diskusi lanjut" ){
        return true
      }else{
        return false
      }
    },
    catatanrequired(){
      if(this.datawawancara.statusrekomendasi2==="Diterima dengan catatan"){
        return true
      }else if(this.datawawancara.statusrekomendasi2==="Diterima dengan syarat"){
        return true
      }else{
        return false
      }
      /*
      let scoreanakakademik = this.datawawancara.score.anak.sikap;
      let scoreanaksikap = this.datawawancara.score.anak.akademik;
      let scoreayah = this.datawawancara.score.ayah;
      let scoreibu = this.datawawancara.score.ibu;
      let scoreanak = scoreanakakademik+scoreanaksikap;
      let scoreortu = scoreayah+scoreibu;
      this.kesimpulan(scoreortu,scoreanak);
      if(scoreortu>=6 && scoreortu<8 && scoreanak>=6 && scoreanak<8){
        return true;
      }else{
        return false;
      }
       */
    },
  },
  validations() {
    return{
      datawawancara:{
        catatan: requiredIf(this.catatanrequired),
        pewawancara:{required},
        komunikasi: {
          keteranganumum:{},
          polakomunikasi:{required},
          respek:{required},
          terimamasukan:{required},
          energidiskusi:{required}
        },
        pola:{
          keteranganumum:{},
          polasuh:{required},
          struktur:{required},
          praktik:{required},
          kontribusi:{required},
          lainlain:{},
        },
        score:{
          ayah:{
            minValueValue:minValue(0)
          },
          ibu:{
            minValueValue: minValue(0)
          }
        }
      }
    }
  },
  methods: {
    kesimpulan2(anak,ayah,ibu){
      if(anak>=14 && ayah >= 11 && ibu >= 11){
        this.datawawancara.statusrekomendasi = "Diterima";
      }else{
        this.datawawancara.statusrekomendasi = "Ditolak";
      }
    },
    kesimpulan(scoreortu,scoreanak){
      if(scoreortu>=8 && scoreanak>=8){
        this.datawawancara.statusrekomendasi = "Diterima tanpa catatan.";
      }else if(scoreortu>=6 && scoreanak>=6){
        this.datawawancara.statusrekomendasi = "Diterima dengan catatan.";
      }else if(scoreortu>=6 && scoreanak>=4 ){
        this.datawawancara.statusrekomendasi = "Diterima dengan syarat.";
      }else{
        this.datawawancara.statusrekomendasi = "Ditolak.";
      }
    },
    removehadir(ortu){
      //this.datawawancara.listhadir = this.daftarhadir;
      const index = this.datawawancara.listhadir.indexOf(ortu);
      if (index > -1) { // only splice array when item is found
        this.datawawancara.listhadir.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    yanghadir(){
      if(this.hadir.length>0){
        if(!this.datawawancara.listhadir.includes(this.hadir)){
          this.datawawancara.listhadir.push(this.hadir);
        }
      }
    },
    ubahvaluedilevel(arr,level){
      console.log(arr,level)
    },
    ubahvaluedilevel2(arr,level){
      const idxintegritas = arr.integritas.indexOf(level);
      const idxberkembang = arr.berkembang.indexOf(level);
      const idxkolaborasi = arr.kolaborasi.indexOf(level);
      const idxpengendaliandiri = arr.pengendaliandiri.indexOf(level);
      const idxmembangunhubungan = arr.membangunhubungan.indexOf(level);

      if (idxintegritas > -1){
        arr.integritas.splice(idxintegritas,1);
      }
      if (idxberkembang > -1){
        arr.berkembang.splice(idxberkembang,1);
      }
      if (idxkolaborasi > -1){
        arr.kolaborasi.splice(idxkolaborasi,1);
      }
      if (idxpengendaliandiri > -1){
        arr.pengendaliandiri.splice(idxpengendaliandiri,1);
      }
      if (idxmembangunhubungan > -1){
        arr.membangunhubungan.splice(idxmembangunhubungan,1);
      }
    },

    aturanlevel1(arr,level){
      let vintegritas = arr.integritas.indexOf(level) >=0;
      let vberkembang = arr.berkembang.indexOf(level) >=0;
      let vkolaborasi = arr.kolaborasi.indexOf(level) >=0;
      let vpengendaliandiri = arr.pengendaliandiri.indexOf(level) >=0;
      let vmembangunhubungan = arr.membangunhubungan.indexOf(level) >=0;
      if(vintegritas && vberkembang && vkolaborasi && vpengendaliandiri && vmembangunhubungan){
        return false
      }else{
        return true
      }
    },
    aturanlevel(arr,nilai){
      //console.log(arr,nilai);
      if (arr.indexOf(nilai) >= 0){
        //return false
      }else{
        //return true
      }
    },
    checkAturan(arr,nilai){
      if (arr.indexOf(nilai) >= 0){
        return false
      }else{
        return true
      }
    },
    docalculate(){
      //this.hitungscoringortu(this.datawawancara.nilai.ayah,'ayah');
      //this.hitungscoringortu(this.datawawancara.nilai.ibu,'ibu');
      let scoreanakakademik = this.datawawancara.score.anak.sikap;
      let scoreanaksikap = this.datawawancara.score.anak.akademik;
      let scoreayah = this.datawawancara.score.ayah;
      let scoreibu = this.datawawancara.score.ibu;
      let scoreanak = scoreanakakademik+scoreanaksikap;
      let scoreortu = parseInt(scoreayah)+parseInt(scoreibu);
      this.kesimpulan(scoreortu,scoreanak);
    },

    hitungscoringortu(arr,target){
      let path = '/dashboard/hitungscoreortu';
      let data = arr;
      let score = 0;
      DashboardServices.postData(data, path).then(
          (response) => {
            if (response.data.status === 200) {
              score = response.data.data.total;
              if(target==='ayah'){
                this.datawawancara.score.ayah = score;
              }else if(target==='ibu'){
                this.datawawancara.score.ibu = score;
              }else if(target==='anak'){
                this.datawawancara.score.anak = score;
              }
              this.kesimpulan2(this.datawawancara.score.anak.akademik,this.datawawancara.score.ayah,this.datawawancara.score.ibu);
            }
          }
      );
    },
    hitungscoringortu1(arr,target){
      let vintegritas = Math.max(...arr.integritas);
      let vberkembang = Math.max(...arr.berkembang);
      let vkolaborasi = Math.max(...arr.kolaborasi);
      let vpengendaliandiri = Math.max(...arr.pengendaliandiri);
      let vmembangunhubungan = Math.max(...arr.membangunhubungan);
      vintegritas = isFinite(vintegritas)? vintegritas:0;
      vberkembang = isFinite(vberkembang)? vberkembang:0;
      vkolaborasi = isFinite(vkolaborasi)? vkolaborasi:0;
      vpengendaliandiri = isFinite(vpengendaliandiri)? vpengendaliandiri:0;
      vmembangunhubungan = isFinite(vmembangunhubungan)? vmembangunhubungan:0;
      //let nilai = vintegritas+vberkembang+vkolaborasi+vpengendaliandiri+vmembangunhubungan;
      let nilai =[vintegritas,vberkembang,vkolaborasi,vpengendaliandiri,vmembangunhubungan];
      let score = Math.max(...nilai);
      if(target==='ayah'){
        this.datawawancara.score.ayah = score;
      }else if(target==='ibu'){
        this.datawawancara.score.ibu = score;
      }else if(target==='anak'){
        this.datawawancara.score.anak = score;
      }
      return score;
      //return "";
    },
    viewberasa(item) {
      this.berasaheader = item.tipe_pengisi;
      this.currentberasa = item.data.formulir;
      this.visible = true;
    },
    beforeUploadSatu(request) {
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname', 'wawancaraortu');
      request.formData.append('invid', this.data.invcode);
      return request;
    },
    onProgressSatu(event) {
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event) {
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if (obj.code === 1) {
        this.classupload1 = 'bg-success';
        this.$toast.add({severity: 'success', summary: message, detail: 'File Uploaded', life: 3000});
      } else {
        this.classupload1 = 'bg-danger';
        this.$toast.add({severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000});
      }
      this.isupload1 = false;
    },
    onSelectSatu(event) {
      if (event.files.length > 0) {
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    getClass(id) {
      if (this.page === id) {
        return 'nav-link active';
      } else {
        return 'nav-link';
      }
    },
    getTabClass(id) {
      if (this.page === id) {
        return 'tab-pane container active';
      } else {
        return 'tab-pane container fade';
      }
    },
    setpage(id) {
      this.page = id;
    },
    toggleview() {
      this.$emit('change-view', true)
    },
    async updateHasilWawancaraOrtu() {
      const result = await this.v$.datawawancara.$validate();
      if(result){
        this.loading = true;
        this.$store.commit('loading', this.loading);
        //this.datawawancara.listhadir = this.daftarhadir;
        let data = {
          'idjadwal': this.data.jadwal_id,
          'status': this.statuspelaksanaan,
          'catatan': this.catatan,
          'data': this.data,
          'wawancara': this.datawawancara
        };
        let path = '/dashboard/updateHasilKegiatan';
        //this.kesimpulan(scoreortu,scoreanak);
        DashboardServices.postData(data, path).then(
            (response) => {
              this.response = response.data;
              this.message = response.data.message;
              if (response.data.status === 200) {
                this.successtrx = true;
                this.messagetrx = response.data.data.message;
              } else {
                this.successtrx = false;
                this.messagetrx = response.data.data.message;
              }
              this.loading = false;
              this.$store.commit('loading', this.loading);
            }
        );
      }else{
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar';
      }
    },
    generatelink(invchild) {
      return "/admin/data/view-formulir-psb/" + invchild;
    },
    getdataHasilWawancaraOrtu(){
      this.loading = true;
      this.$store.commit('loading', this.loading);
      let data = {
        idanak: this.data.idanak,
        //periode: this.data.idformulir
      };
      let path = '/dashboard/getDataWawancaraOrtu';
      DashboardServices.postData(data, path).then(
          (response) => {
            this.response = response.data;
            this.datawawancara = response.data.data.wawancara;
            this.datadiubah = response.data.data.editat;
          }
      )
    },
    getNilaiAnak(){
      this.loading = true;
      this.$store.commit('loading', this.loading);
      let data = {
        idanak: this.data.idanak,
        //periode: this.data.idformulir
      };
      let path = '/dashboard/getDataScoreAnak';
      DashboardServices.postData(data, path).then(
          (response) => {
            this.response = response.data;
            this.datawawancara.score.anak.akademik = response.data.data.akademik;
            this.datawawancara.score.anak.sikap = response.data.data.sikap;
          }
      )
    },
    getDataKegiatan() {
      this.loading = true;
      this.$store.commit('loading', this.loading);
      let data = {
        invid: this.data.idanak,
        idformulir: this.data.idformulir
      };
      let path = '/dashboard/getDataHasilKegiatanAnak';
      DashboardServices.postData(data, path).then(
          (response) => {
            this.response = response.data;
            this.message = response.data.message;
            if (response.data.status === 200) {
              this.success = true;
              this.datakegiatan = this.response.data;
              this.berasa = this.datakegiatan.berasa;
              if (typeof this.response.data.psikolog.catatan_kegiatan !== 'undefined') {
                this.datacatatan.psikolog = this.response.data.psikolog.catatan_kegiatan;
              }
              if (typeof this.response.data.kognitif.catatan_kegiatan !== 'undefined') {
                this.datacatatan.kognitif = this.response.data.kognitif.catatan_kegiatan;
              }
              if (typeof this.response.data.cobabelajar.catatan_kegiatan !== 'undefined') {
                this.datacatatan.cobabelajar = this.response.data.cobabelajar.catatan_kegiatan;
              }
              if (typeof this.response.data.catatandiskusi !== 'undefined') {
                this.datacatatan.catatandiskusi = this.response.data.catatandiskusi;
              }
            } else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading', this.loading);
          }
      );
    },
    getDataUploadedFile() {
      this.loading = true;
      this.$store.commit('loading', this.loading);
      let data = {
        paramname: this.data.invcode,
        //filecat:'wawancaraortu'
      };
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data, path).then(
          (response) => {
            this.response = response.data;
            this.message = response.data.message;
            if (response.data.status === 200) {
              this.success = true;
              if (typeof this.response.data.wawancaraortu !== 'undefined') {
                this.filelist = this.response.data.wawancaraortu;
              }
              if (typeof this.response.data.kelaspercobaan !== 'undefined') {
                this.filelistPercobaan = this.response.data.kelaspercobaan;
              }
              if (typeof this.response.data.testkognitif !== 'undefined') {
                this.filelistKognitif = this.response.data.testkognitif;
              }
              if (typeof this.response.data.wpsikolog !== 'undefined') {
                this.filelistPsikolog = this.response.data.wpsikolog;
              }
            } else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading', this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.catatan = this.data.catatan_kegiatan;
    this.statuspelaksanaan = this.data.rekomendasi;
    this.getdataHasilWawancaraOrtu();
    this.getDataUploadedFile();
    this.getDataKegiatan();
    //this.getNilaiAnak();
  }
}
</script>

<style scoped>
.bg-green {
  background-color: #d6f7d4;
}
</style>