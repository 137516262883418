<template>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Catatan Diskusi - {{ data.anak_nama }} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a @click="toggleview" href="#" class="btn btn-sm btn-light"><i
            class="fa-solid fa-caret-left"></i></a></div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="notes-notification-sga">
      <p><strong>Catatan :</strong><br>Tambahkan catatan hasil diskusi dibawah ini. Catatan diskusi wajib diisi
        agar Ananda dapat masuk ke tahap wawancara</p>
    </div>
    <div class="form-general">
      <div class="row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-12 spacer-field-sga">
              <normal-label>No formulir</normal-label>
              <br>
              <normal-label2>{{ data.naked_invid }}</normal-label2>
            </div>
            <div class="col-12 spacer-field-sga">
              <normal-label>Nama Ananda</normal-label>
              <br>
              <normal-label2>{{ data.anak_nama }}</normal-label2>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-12 spacer-field-sga  w-100"><label>Catatan Akademik Anak</label></div>
            <div class="col-12  spacer-field-sg">
              <textarea v-model="dataForm.catatanakademik" class="form-control textarea-sga" rows="3"
                        required=""></textarea>
              <span v-if="v$.dataForm.catatanakademik.$error" class="text-danger">Mohon isi catatan akademik anak</span>
            </div>
            <div class="col-12 spacer-field-sga  w-100">
              <normal-label><strong>Scoring Akademik Anak</strong></normal-label>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Komunikasi</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreKomunikasi" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreKomunikasi.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Motorik halus</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreMotorikHalus" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreMotorikHalus.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Pra CaLisTung</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scorePraCalistung" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scorePraCalistung.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Pemecahan masalah</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scorePemecahMasalah" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scorePemecahMasalah.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Motorik kasar</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreMotorikKasar" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreMotorikKasar.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Daya ingat</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreDayaIngat" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreDayaIngat.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Konsentrasi</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreKonsentrasi" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreKonsentrasi.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-12 spacer-field-sga  w-100"><label>Catatan Sikap Anak</label></div>
            <div class="col-12  spacer-field-sg">
              <textarea class="form-control textarea-sga" v-model="dataForm.catatansikap" rows="3"
                        required=""></textarea>
              <span v-if="v$.dataForm.catatansikap.$error" class="text-danger">Mohon isi catatan akademik anak</span>
            </div>
            <div class="col-12 spacer-field-sga  w-100">
              <normal-label><strong>Scoring Sikap Anak</strong></normal-label>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Penyesuaian diri</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scorePenyesuaianDiri" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scorePenyesuaianDiri.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Kerja sama</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreKerjaSama" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreKerjaSama.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-4 spacer-field-sga  w-100"><label>Kemandirian</label></div>
            <div class="col-md-8 spacer-field-sga">
              <select v-model="dataForm.scoreKemandirian" class="w-100 form-control" required>
                <option value="">Pilih nilai</option>
                <option value="1">Kurang</option>
                <option value="2">Sesuai tahapan usia</option>
                <option value="3">Melebihi</option>
              </select>
              <span v-if="v$.dataForm.scoreKemandirian.$error" class="text-danger">Mohon isi score</span>
            </div>
            <div class="col-12 spacer-field-sga  w-100"><label>Catatan kemandirian</label></div>
            <div class="col-12  spacer-field-sg">
              <textarea v-model="dataForm.scoreCatatanKemandirian"  class="form-control textarea-sga" rows="3" required=""></textarea>
              <span v-if="v$.dataForm.scoreCatatanKemandirian.$error" class="text-danger">Mohon isi score</span>
            </div>

            <div class="col-12 spacer-field-sga  w-100"><label>Yang masih perlu dikembangkan</label></div>
            <div class="col-12  spacer-field-sg">
              <textarea class="form-control textarea-sga" v-model="dataForm.perlukembangkan" rows="3"
                        required=""></textarea>
              <span v-if="v$.dataForm.perlukembangkan.$error" class="text-danger">Mohon isi catatan akademik anak</span>
            </div>
            <div class="col-12 spacer-field-sga  w-100"><label>Apa yang perlu ditanyakan lebih lanjut kepada orang
              tua </label></div>
            <div class="col-12  spacer-field-sg">
              <textarea class="form-control textarea-sga" v-model="dataForm.tanyaortu" rows="3" required=""></textarea>
              <span v-if="v$.dataForm.tanyaortu.$error" class="text-danger">Mohon isi catatan akademik anak</span>
            </div>
            <div class="col-12">
              <hr>
            </div>
            <div class="col-md-6 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12">
                  <label>Kualitas informasi</label>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="kurang, hanya sedikit"
                           v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck1">
                      kurang, hanya sedikit
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="cukup" v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      cukup
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="lengkap" v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      lengkap
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="sangat detail"
                           v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      sangat detail
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="cenderung lihat hal negatif saja"
                           v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      cenderung lihat hal negatif saja
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="cenderung lihat hal positif saja"
                           v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      cenderung lihat hal positif saja
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="imbang dalam melihat anak"
                           v-model="dataForm.kualitasinformasi">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      imbang dalam melihat anak
                    </normal-label2>
                  </div>
                </div>
                <div class="col-12" style="margin-top: 20px;">
                  <label>Catatan</label>
                  <textarea class="form-control textarea-sga" v-model="dataForm.kualitascatatan"
                            rows="3" required=""></textarea>
                  <keterangan-form> bila ada</keterangan-form>
                </div>
              </div>
            </div>
            <div class="col-md-6 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12">
                  <label>Temperamen</label>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="lambat panas" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck1">
                      lambat panas
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="introvert" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      introvert
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="mudah kecil hati"
                           v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      mudah kecil hati
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="perasa/emosional"
                           v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      perasa/emosional
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="pasif" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      pasif
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="mudah sesuaikan diri"
                           v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      mudah sesuaikan diri
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="ekstrovert" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      ekstrovert
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="percaya diri" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      percaya diri
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="periang" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      periang
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="cuek" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      cuek
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="sukar diatur" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      sukar diatur
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="dominan" v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      dominan
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="menunjukkan agresi"
                           v-model="dataForm.temperamen">
                    <normal-label2 class="form-check-label" for="defaultCheck2">
                      menunjukkan agresi
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input type="text" class="w-100 form-control" required="" v-model="dataForm.temperamencatatan">
                    <keterangan-form>temperamen lainnya bila ada, bila tidak ada tulis tidak
                      ada
                    </keterangan-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr>
              <h5>Hubungan dan interaksi</h5>
              <hr>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12  spacer-field-sga "><label>Keterlibatan orang tua</label></div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.keterlibatanortu"
                           value="Ibu dominan" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">Ibu dominan
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.keterlibatanortu"
                           value="Ayah dominan" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">Ayah dominan
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.keterlibatanortu"
                           value="Setara" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">Setara
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.keterlibatanortu"
                           value="Lainnya" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">Lainnya
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input type="text" class="w-100 form-control" required="" v-model="dataForm.keterlibatanortulainnya">
                    <keterangan-form>tuliskan bila pilih lainnya</keterangan-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12  spacer-field-sga "><label>Kedekatan dalam keluarga</label></div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kedekatandalamkeluarga"
                           value="tidak terkesan hangat" name="bangunpagi" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">tidak terkesan
                      hangat
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kedekatandalamkeluarga"
                           value="hangat, akrab" name="bangunpagi" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">hangat,
                      akrab
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kedekatandalamkeluarga"
                           value="sangat hangat,akrab" name="bangunpagi" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">sangat hangat,
                      akrab
                    </normal-label2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <label>Catatan</label>
              <textarea class="form-control textarea-sga" v-model="dataForm.kedekatandalamkeluargacatatan" rows="3"
                        required=""></textarea>
              <keterangan-form> bila ada</keterangan-form>
            </div>
            <!--batas-->
            <div class="col-12">
              <hr>
              <h5>Aktivitas dan kemandirian anak</h5>
              <hr>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12  spacer-field-sga "><label>Aktivitas</label></div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.aktivitas"
                           value="Tidak banyak" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">Tidak banyak
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.aktivitas"
                           value="Cukup banyak" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">Cukup banyak
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.aktivitas"
                           value="Sangat banyak" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">Sangat
                      banyak
                    </normal-label2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12  spacer-field-sga "><label>Kemandirian</label></div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kemandirian"
                           value="Kurang mandiri" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">Kurang
                      mandiri
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kemandirian"
                           value="Cukup mandiri" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">Cukup
                      mandiri
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kemandirian"
                           value="Sangat mandiri"  required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">Sangat
                      mandiri
                    </normal-label2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <label>Catatan</label>
              <textarea class="form-control textarea-sga" v-model="dataForm.kemandiriancatatan" rows="3"
                        required=""></textarea>
              <keterangan-form> bila ada</keterangan-form>
            </div>
            <!--batas-->
            <div class="col-12">
              <hr>
              <h5>Kesehatan</h5>
              <hr>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <label>Limitasi kesehatan</label>
              <textarea class="form-control textarea-sga" v-model="dataForm.limitasikesehatan" rows="3"
                        required=""></textarea>
              <keterangan-form> bila ada, bila tidak ada tulis tidak ada</keterangan-form>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <label>Kebutuhan khusus/kesubel</label>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kebutuhankhusus"
                           value="Ya" name="Ya" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">Ya
                    </normal-label2>
                  </div>
                  <br>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kebutuhankhusus"
                           value="Tidak" name="Tidak" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">Tidak
                    </normal-label2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <normal-label>Tambahan</normal-label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="Lahir prematur" v-model="dataForm.tambahan">
                <normal-label2 class="form-check-label" for="defaultCheck2">
                  Lahir prematur
                </normal-label2>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="Speech delay" v-model="dataForm.tambahan">
                <normal-label2 class="form-check-label" for="defaultCheck2">
                  <em>Speech delay</em>
                </normal-label2>
              </div>
            </div>
            <!--batas-->
            <div class="col-12">
              <hr>
              <h5>Rekam Akademik</h5>
              <hr>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12  spacer-field-sga "><label>Raport TK</label></div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.raporttk"
                           value="Kurang" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">Kurang
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.raporttk"
                           value="Baik" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">Baik
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.raporttk"
                           value="Sangat Baik" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">Sangat Baik
                    </normal-label2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <div class="row">
                <div class="col-12  spacer-field-sga "><label>Kehadiran</label></div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="dataForm.kehadiran"
                           value="Banyak absen" required="">
                    <normal-label2 class="form-check-label" for="inlineCheckbox1">Banyak absen
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                           value="Sedikit Absen" required="" v-model="dataForm.kehadiran">
                    <normal-label2 class="form-check-label" for="inlineCheckbox2">Sedikit
                      absen
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                           value="Jarang Absen" required="" v-model="dataForm.kehadiran">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">Jarang absen
                    </normal-label2>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"
                           value="Keterlambatan" required="" v-model="dataForm.kehadiran">
                    <normal-label2 class="form-check-label" for="inlineCheckbox3">
                      Keterlambatan
                    </normal-label2>
                  </div>
                  <div class="form-check">
                    <input type="text" class="w-100 form-control" required="" v-model="dataForm.kehadiranketerangan">
                    <keterangan-form>keterangan bila ada</keterangan-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 spacer-field-sga  w-100">
              <label>Catatan</label>
              <textarea class="form-control textarea-sga" id="exampleFormControlTextarea1" rows="3"
                        required="" v-model="dataForm.catatanakhir"></textarea>
              <keterangan-form> bila ada</keterangan-form>
            </div>
            <!--batas-->
            <div class="col-12">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <normal-label>Status Rekomendasi : <select v-model="dataForm.status" class="sga-status-dropdown">
                <option value="Belum ada rekomendasi">Belum ada rekomendasi</option>
                <option value="Tidak diterima">Tidak diterima</option>
                <option value="Ulangi kelas coba belajar">Ulangi kelas coba belajar</option>
                <option value="Test kognitif">Test kognitif</option>
                <option value="Lanjut wawancara">Lanjut wawancara</option>
              </select></normal-label>
            </div>
          </div>
          <div class="row mt-2" v-if="message">
            <div class="col-12">
              <AlertSuccess v-if="success" :message="message"></AlertSuccess>
              <AlertDanger v-else :message="message"></AlertDanger>
            </div>
          </div>
          <div class="col-12 button-area-sga text-right">
            <button @click="saveDataCatatanDiskusi" class="btn btn-sm btn-success" type="button"> Simpan catatan</button>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import useValidate from '@vuelidate/core';
import {required, numeric, minLength} from '@vuelidate/validators'

export default {
  name: "PageUpdateCatatanDiskusi",
  components: {AlertDanger, AlertSuccess},
  emits: ['change-view'],
  props: {
    data: undefined
  },
  data: () => ({
    v$: useValidate(),
    loading: false,
    message: "",
    success: false,
    dataForm: {
      invid: "",
      catatanakademik: "",
      scoringakademik: 0,
      scoreKomunikasi:"",
      scoreMotorikHalus:"",
      scorePraCalistung:"",
      scorePemecahMasalah:"",
      scoreMotorikKasar:"",
      scoreDayaIngat:"",
      scoreKonsentrasi:"",
      scorePenyesuaianDiri:"",
      scoreKerjaSama:"",
      scoreKemandirian:"",
      scoreCatatanKemandirian:"",
      catatansikap: "",
      scoringsikap: 0,
      status: "",
      perlukembangkan: "",
      tanyaortu: "",
      kualitasinformasi: [],
      kualitascatatan: "",
      tambahan:[],
      temperamen: [],
      temperamencatatan: "",
      keterlibatanortu: "",
      keterlibatanortulainnya: "",
      kedekatandalamkeluarga: "",
      kedekatandalamkeluargacatatan: "",
      aktivitas: "",
      kemandirian: "",
      kemandiriancatatan: "",
      limitasikesehatan: "",
      kebutuhankhusus: "",
      kehadiran:"",
      kehadiranketerangan:"",
      catatanakhir:"",
      raporttk:""
    }
  }),
  methods: {
    toggleview() {
      this.$emit('change-view', true)
    },
    saveDataCatatanDiskusi() {
      this.v$.dataForm.$validate();
      if (!this.v$.dataForm.$error) {
        this.loading = true;
        this.$store.commit('loading', this.loading);
        this.dataForm.invid = this.data.invid;
        let data = this.dataForm;
        let path = '/dashboard/simpanCatatanDiskusi';
        DashboardServices.postData(data, path).then(
            (response) => {
              this.response = response.data;
              this.message = response.data.message;
              if (response.data.status === 200) {
                this.success = true;
              } else {
                this.success = false;
              }
              this.loading = false;
              this.$store.commit('loading', this.loading);
            }
        );
      } else {
        this.loading = false;
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  validations() {
    return {
      dataForm: {
        catatanakademik: {required, minLength: minLength(5)},
        scoreKomunikasi: {required, numeric},
        scoreMotorikHalus: {required, numeric},
        scorePraCalistung: {required, numeric},
        scorePemecahMasalah: {required, numeric},
        scoreMotorikKasar: {required, numeric},
        scoreDayaIngat: {required, numeric},
        scoreKonsentrasi: {required, numeric},
        scorePenyesuaianDiri: {required, numeric},
        scoreKerjaSama: {required, numeric},
        scoreKemandirian: {required, numeric},
        scoreCatatanKemandirian: {required, minLength: minLength(5)},
        catatansikap: {required, minLength: minLength(5)},
        status: {required, minLength: minLength(5)},
        perlukembangkan: {required, minLength: minLength(5)},
        tanyaortu: {required, minLength: minLength(5)},
      }
    }
  },
  mounted() {
    this.dataForm.catatanakademik = this.data.catatan_akademik;
    this.dataForm.scoringakademik = this.data.scoring_akademik;
    this.dataForm.catatansikap = this.data.catatan_sikap;
    this.dataForm.scoringsikap = this.data.scoring_sikap;
    this.dataForm.status = this.data.status_rekomendasi;
    this.dataForm.perlukembangkan = this.data.masih_perlu_kembangkan;
    this.dataForm.tanyaortu = this.data.tanya_ortu;
    this.dataForm.kualitasinformasi = this.data.kualitas_informasi;
    this.dataForm.kualitascatatan = this.data.kualitas_informasi_catatan;
    this.dataForm.temperamen = this.data.temperamen;
    this.dataForm.tambahan = this.data.tambahan;
    this.dataForm.keterlibatanortu = this.data.keterlibatan_ortu;
    this.dataForm.keterlibatanortulainnya = this.data.keterlibatan_ortu_catatan;
    this.dataForm.kedekatandalamkeluarga = this.data.kedekatan_keluarga;
    this.dataForm.kedekatandalamkeluargacatatan = this.data.kedekatan_keluarga_catatan;
    this.dataForm.aktivitas = this.data.aktivitas;
    this.dataForm.kemandirian = this.data.kemandirian;
    this.dataForm.kemandiriancatatan = this.data.kemandirian_catatan;
    this.dataForm.limitasikesehatan = this.data.kemandirian_catatan;
    this.dataForm.kebutuhankhusus = this.data.kebutuhan_khusus;
    this.dataForm.tambahan = this.data.tambahan;
    this.dataForm.kehadiran = this.data.kehadiran;
    this.dataForm.kehadiranketerangan = this.data.kehadiran_catatan;
    this.dataForm.catatanakhir = this.data.catatan_akhir;
    this.dataForm.raporttk = this.data.raport_tk;

    this.dataForm.scoreKomunikasi = this.data.data_score.scoreKomunikasi;
    this.dataForm.scoreMotorikHalus = this.data.data_score.scoreMotorikHalus;
    this.dataForm.scorePraCalistung= this.data.data_score.scorePraCalistung;
    this.dataForm.scorePemecahMasalah = this.data.data_score.scorePemecahMasalah;
    this.dataForm.scoreMotorikKasar = this.data.data_score.scoreMotorikKasar;
    this.dataForm.scoreDayaIngat = this.data.data_score.scoreDayaIngat;
    this.dataForm.scoreKonsentrasi = this.data.data_score.scoreKonsentrasi;
    this.dataForm.scorePenyesuaianDiri = this.data.data_score.scorePenyesuaianDiri;
    this.dataForm.scoreKerjaSama = this.data.data_score.scoreKerjaSama;
    this.dataForm.scoreKemandirian = this.data.data_score.scoreKemandirian;
    this.dataForm.scoreCatatanKemandirian = this.data.data_score.scoreCatatanKemandirian;
  }
}
</script>

<style scoped>

</style>