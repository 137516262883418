<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Keputusan > Lihat Data Keputusan > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Hasil Keputusan - {{data.nama_anak}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="form-general">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="col-12 spacer-field-sga">
                <normal-label>No formulir</normal-label>
                <br>
                <normal-label2>{{data.no_formulir}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Ananda</normal-label>
                <br>
                <normal-label2>{{data.nama_anak}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Orang Tua</normal-label>
                <ul>
                  <li>
                    <normal-label2>{{data.nama_ayah}}</normal-label2>
                  </li>
                  <li>
                    <normal-label2>{{data.nama_ibu}}</normal-label2>
                  </li>
                  <li v-if="data.nama_ayah_tiri">{{data.nama_ayah_tiri}}</li>
                  <li v-if="data.nama_ibu_tiri">{{data.nama_ibu_tiri}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a :class="getClass(1)" @click="setpage(1)" data-toggle="tab" href="#home">Keputusan</a>
              </li>
              <li class="nav-item">
                <a :class="getClass(2)" @click="setpage(2)" data-toggle="tab" href="#menu1">Data yang tersedia</a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content" style="margin-top: 30px;">
              <div :class="getTabClass(1)" id="home">
                <div class="row">
                  <div class="col-12 spacer-field-sga">
                    <normal-label>Keputusan PSB : <select v-model="keputusanpsb" class="sga-status-dropdown">
                      <option value="menunggu">Menunggu keputusan</option>
                      <option value="diterima">Diterima</option>
                      <option value="tidak-diterima">Tidak diterima</option>
                    </select></normal-label>
                  </div>
                  <div class="col-12 spacer-field-sga">
                    <button @click="updateHasilKeputusanPSB" class="btn btn-sm btn-success">Simpan</button>	</div>
                </div>
              </div>
              <div :class="getTabClass(2)" id="menu1">
                <div class="row">
                  <div class="col-12"><h5 style="color: darkorange;">Formulir pendaftaran</h5>
                    <p>
                      <router-link :to=generatelink(data.invid) target= '_blank'>
                        Lihat formulir
                      </router-link>
                    </p>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data wawancara awal
                    (Psikologi)</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{datacatatan.psikolog}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPsikolog.list" :num-visible="filelistPsikolog.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data test kognitif </h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{datacatatan.kognitif}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistKognitif.list" :num-visible="filelistKognitif.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data kelas coba belajar</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan PIC PSB</strong> :</p>
                        <p>{{datacatatan.cobabelajar}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPercobaan.list" :num-visible="filelistPercobaan.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data formulir Berasa</h5>
                    <div class="row">
                      <div class="col-12">
                        <ul>
                          <li v-for="(berasa,idx) in berasa" :key="idx">
                            <span class="text-primary" style="cursor: pointer" @click="viewberasa(berasa)" target="_blank">{{berasa.tipe_pengisi}}</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data Catatan Diskusi</h5>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Akademik (maksimal 21)</strong>:</p>
                        <p>{{datacatatan.catatandiskusi.scoring_akademik}}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Akademik Anak</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_akademik}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Sikap (maksimal 9)</strong>:</p>
                        <p>{{datacatatan.catatandiskusi.scoring_sikap}}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Sikap Anak</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_sikap}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12"><p><strong>Yang masih perlu dikembangkan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.masih_perlu_kembangkan}}</p>
                      </div>
                      <div class="col-12"><p><strong>Apa yang perlu ditanyakan lebih lanjut kepada
                        orang tua</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.tanya_ortu}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kualitas informasi</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kualitas_informasi}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kualitas_informasi_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Temperamen</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.temperamen}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.temperamen_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Keterlibatan orang tua</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.keterlibatan_ortu}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.keterlibatan_ortu_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kedekatan dalam keluarga</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kedekatan_keluarga}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kedekatan_keluarga_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Aktivitas</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.aktivitas}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kemandirian</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kemandirian}}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kemandirian_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Limitasi kesehatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.limitasi}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kebutuhan khusus/kesubel</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kebutuhan_khsusu}}</p>
                      </div>
                      <div class="col-4"><p><strong>Tambahan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.tambahan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Raport TK</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.raport_tk}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kehadiran</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kehadiran}}</p>
                        <p>{{datacatatan.catatandiskusi.kehadiran_catatan}}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_akhir}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data hasil wawancara</h5>
                    <div class="row">
                      <div class="col-12"><div class="notes-notification-sga2"><p><strong>KESIMPULAN</strong></p>
                        <p>berdasarkan seluruh data administratif siswa dan orang tua, pengamatan siswa oleh guru kelas,
                          asesmen siswa oleh pengamat, wawancara orang tua, dan interaksi orang tua selama proses PSB.
                        </p>

                      </div></div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <h5>Ringkasan anak</h5>
                        <div class="row"><div class="col-12">
                          <div class="table-responsive">
                            <table class="table table-striped ">
                              <thead>
                              <tr>
                                <th scope="row">Nilai Akademik  </th>
                                <th scope="col">Nilai Sikap</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>{{data.datawawancara.score.anak.akademik}}</td>
                                <td>{{data.datawawancara.score.anak.sikap}}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <h5>Ringkasan orang tua</h5>
                        <div class="row"><div class="col-12">
                          <div class="table-responsive">
                            <table class="table table-striped ">
                              <thead>
                              <tr>
                                <th scope="row">Ayah  </th>
                                <th scope="row">Ibu</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>{{data.datawawancara.score.ayah}}</td>
                                <td>{{data.datawawancara.score.ibu}}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 spacer-field-sga  w-100">
                        <normal-label>Status Rekomendasi : <strong>{{data.datawawancara.keputusandiskusi}}</strong></normal-label>
                      </div>
                      <div class="col-12  spacer-field-sga">
                        <label>Catatan atau syarat penerimaan</label>
                        <textarea class="form-control textarea-sga" id="exampleFormControlTextarea1" v-model="catatanwawancara"  rows="4" required=""></textarea>
                      </div>
                      <div class="col-12 spacer-field-sga d-none"><p><a href="tautan langsung ke formulir" target="_blank">Lihat hasil wawancara lengkap</a></p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageUpdateDataKeputusan",
  components:{BreadCrumb,FileLister},
  props:{
    data: undefined
  },
  emits: ['change-view'],
  data: () => ({
    loading: false,
    message:"",
    messagetrx:"",
    success: false,
    successtrx: false,
    showupload: true,
    classupload1: '',
    urlupload: '',
    catatanwawancara:'',
    uploadLabel1: '',
    visible: false,
    filelist: [],
    berasaheader: "",
    currentberasa: [],
    page: 2,
    berasa: [],
    catatan: '',
    keputusanpsb:'',
    statuspelaksanaan: '',
    filelistPercobaan: [],
    filelistKognitif: [],
    filelistPsikolog: [],
    datacatatan: {
      psikolog: '',
      cobabelajar: '',
      kognitif: '',
      catatandiskusi: []
    },
    datakegiatan: []
  }),
  methods:{
    getClass(id) {
      if (this.page === id) {
        return 'nav-link active';
      } else {
        return 'nav-link';
      }
    },
    getTabClass(id) {
      if (this.page === id) {
        return 'tab-pane container active';
      } else {
        return 'tab-pane container fade';
      }
    },
    setpage(id) {
      this.page = id;
    },
    toggleview() {
      this.$emit('change-view', true)
    },
    viewberasa(item) {
      this.berasaheader = item.tipe_pengisi;
      this.currentberasa = item.data.formulir;
      this.visible = true;
    },
    generatelink(invchild) {
      return "/admin/data/view-formulir-psb/" + invchild;
    },
    updateHasilKeputusanPSB() {
      this.loading = true;
      this.$store.commit('loading', this.loading);
      let data = {
        'idanak': this.data.idanak,
        'status': this.keputusanpsb,
      };
      let path = '/dashboard/updateDataKeputusanPSB';
      DashboardServices.postData(data, path).then(
          (response) => {
            this.response = response.data;
            this.message = response.data.message;
            if (response.data.status === 200) {
              this.successtrx = true;
              this.messagetrx = 'Data berhasil disimpan';
            } else {
              this.successtrx = false;
              this.messagetrx = 'Data gagal disimpan';
            }
            this.loading = false;
            this.$store.commit('loading', this.loading);
          }
      );
    },
    getDataKegiatan() {
      this.loading = true;
      this.$store.commit('loading', this.loading);
      let data = {
        invid: this.data.idanak,
        idformulir: this.data.idformulir
      };
      let path = '/dashboard/getDataHasilKegiatanAnak';
      DashboardServices.postData(data, path).then(
          (response) => {
            this.response = response.data;
            this.message = response.data.message;
            if (response.data.status === 200) {
              this.success = true;
              this.datakegiatan = this.response.data;
              this.berasa = this.datakegiatan.berasa;
              if (typeof this.response.data.psikolog.catatan_kegiatan !== 'undefined') {
                this.datacatatan.psikolog = this.response.data.psikolog.catatan_kegiatan;
              }
              if (typeof this.response.data.kognitif.catatan_kegiatan !== 'undefined') {
                this.datacatatan.kognitif = this.response.data.kognitif.catatan_kegiatan;
              }
              if (typeof this.response.data.cobabelajar.catatan_kegiatan !== 'undefined') {
                this.datacatatan.cobabelajar = this.response.data.cobabelajar.catatan_kegiatan;
              }
              if (typeof this.response.data.catatandiskusi !== 'undefined') {
                this.datacatatan.catatandiskusi = this.response.data.catatandiskusi;
              }
            } else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading', this.loading);
          }
      );
    }
  },
  mounted() {
    this.catatanwawancara = this.data.datawawancara.catatan;
    this.keputusanpsb = this.data.statuskeputusan;
    this.getDataKegiatan();
  }
}
</script>

<style scoped>

</style>